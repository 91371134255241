import * as Sentry from '@sentry/nuxt';

Sentry.init({
  dsn: 'https://d5228c1648a4d65c01670105d0a9907a@o4506994543624192.ingest.us.sentry.io/4507985463672832',

  environment: 'development',

  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
    Sentry.replayIntegration({
      maskAllInputs: false,
      maskAllText: false,
      networkDetailAllowUrls: [
        'https://www.artcurial.com/api',
        'https://identitytoolkit.googleapis.com',
      ],
    }),
  ],
  replaysOnErrorSampleRate: 1.0,

  replaysSessionSampleRate: 0.1,

  // We recommend adjusting this value in production, or using tracesSampler for finer control
  tracesSampleRate: 1.0,
});
